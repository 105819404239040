<script lang="ts" setup>
const route = useRoute()
const { $ROUTE } = useNuxtApp()

const is2faContactUs = computed(() =>
  [
    $ROUTE.TWO_FACTOR_AUTHENTICATION_CONTACT_US,
    $ROUTE.TWO_FACTOR_AUTHENTICATION_CONTACT_US_SUCCESS,
    $ROUTE.TWO_FACTOR_AUTHENTICATION_CONTACT_US_ERROR,
  ].includes(route.path),
)
</script>

<template>
  <div
    class="flex min-h-screen flex-col"
    :class="{ 'bg-bgr md:bg-bgr-100': is2faContactUs }"
  >
    <LayoutHeaderLoggedOut />

    <slot />

    <OnDevices v-if="is2faContactUs" rule=">=md">
      <LayoutFooter style="flex-shrink: 0" />
    </OnDevices>
    <LayoutFooter v-else />
  </div>
</template>
